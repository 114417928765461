import { Authenticated, Refine } from "@refinedev/core";
import { DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  RefineThemes,
  ThemedLayoutV2,
} from "@refinedev/mui";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
  ThemeProvider 
}from "@mui/material";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";

import { SocketProvider } from './utils/socketContext';  

import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
} from "pages/categories";
import {
  ProductCreate,
  ProductEdit,
  ProductList,
  ProductShow,
} from "pages/products";
import {
  ProductGroupCreate,
  ProductGroupList,
} from "pages/productGroups";
import {
  MenuList,
  MenuEdit,
  MenuShow
} from "pages/menus";
import {
  RestaurantBrandList
} from "pages/restaurant-brands";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import { Header, Title } from "./components";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { MuiInferencer } from "@refinedev/inferencer/mui";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import PeopleOutlineOutlined from "@mui/icons-material/PeopleOutlineOutlined";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { RestaurantCreate, RestaurantEdit, RestaurantList, RestaurantShow } from "pages/restaurants";
import { OrderCreate, OrderEdit, OrderList, OrderShow } from "pages/orders";
import { AddressCreate, AddressEdit, AddressList, AddressShow } from "pages/addresses";
import { UserCreate, UserEdit, UserList, UserShow } from "pages/users";
import { SupplementGroupList } from "pages/supplementGroups";
import { MenuCreate } from "pages/menus/create";
import { io } from "socket.io-client";


export const socket = io('https://api.backend.saheat.tn');

function App() {
  const { t, i18n } = useTranslation();

  // You should implement these functions or redefine them as per your resources and roles
  const adminResources = [
    {
      name: "menus",
      list: "/menus",
      create: "/menus/create",
      edit: "/menus/update/:id",
      show: "/menus/show/:id",
      meta: {
        canDelete: true,
        icon: <MenuBookIcon/>
      },
    },
    {
      name: "restaurant-brands",
      list: "/restaurant-brands",
      create: "/restaurant-brands/create",
      edit: "/restaurant-brands/update/:id",
      show: "/restaurant-brands/show/:id",
      meta: {
        canDelete: true,
        icon: <MenuBookIcon/>
      },
    },
    {
      name: "restaurants",
      list: "/restaurants",
      create: "/restaurants/create/",
      edit: "/restaurants/update/:id",
      show: "/restaurants/show/:id",
      meta: {
        canDelete: true,
        icon: <RestaurantIcon />,
      },
    },
    {
      name: "groupedOrders",
      list: "/groupedOrders",
      create: "/groupedOrders/create/",
      edit: "/groupedOrders/update/:id",
      show: "/groupedOrders/show/:id",
      meta: {
        canDelete: true,
        icon: <AddShoppingCartOutlinedIcon />,
      },
    },
   /* {
      name: "addresses",
      list: "/addresses",
      create: "/addresses/create/",
      edit: "/addresses/update/:id",
      show: "/addresses/show/:id",
      meta: {
        canDelete: true,
        icon: <HomeOutlinedIcon />,
      },
    },*/
    {
      name: "users",
      list: "/users",
      create: "/users/create/",
      edit: "/users/update/:id",
      show: "/users/show/:id",
      meta: {
        canDelete: true,
        icon: <PeopleOutlineOutlined />,
      },
    }
  ];
  const dispatcherResources = [
    {
      name: "addresses",
      list: "/addresses",
      create: "/addresses/create/",
      edit: "/addresses/update/:id",
      show: "/addresses/show/:id",
      meta: {
        canDelete: true,
        icon: <HomeOutlinedIcon />,
      },
    },
    {
      name: "users",
      list: "/users",
      create: "/users/create/",
      edit: "/users/update/:id",
      show: "/users/show/:id",
      meta: {
        canDelete: true,
        icon: <PeopleOutlineOutlined />,
      },
    }
  ];
  let roleBasedResources = adminResources;
  // Conditionally setting resources depending on the role
  // if (identity && identity.role === "admin") {
  //   roleBasedResources = adminResources;
  // } else if (identity && identity.role === "dispatcher") {
  //   roleBasedResources = dispatcherResources;
  // }

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };


  const overridedLightTheme = {
    ...RefineThemes.GreenDark,
    palette: {
      ...RefineThemes.GreenDark.palette,
      bgColor: "gray"
    },
  };





  return (
    <ThemeProvider theme={RefineThemes.GreenDark}>
    <BrowserRouter>
      {/* <GitHubBanner /> */}
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" }, main: {
    backgroundColor: "#f0f0f0 !important"
  } }} />
          <RefineSnackbarProvider>
            <DevtoolsProvider>
            <SocketProvider>
              <Refine
                dataProvider={dataProvider("https://api.backend.saheat.tn/api/v1/admin")}
                notificationProvider={notificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                resources={roleBasedResources}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  useNewQueryKeys: true,
                  projectId: "BlW85c-kkBMvU-Hp6tXY",
                }}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-inner"
                        fallback={<CatchAllNavigate to="/login" />}
                      >
                        <ThemedLayoutV2
                          Header={() => <Header isSticky={true} />}
                          Title={Title}
                          // Sider={() => <CustomSider/>}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route
                      index
                      element={<NavigateToResource resource="menus" />}
                    />
                    <Route path="/productGroups">
                      <Route index element={<ProductGroupList />} />
                      {/* <Route index element={<MuiInferencer />} /> */}
                      <Route path="create" element={<ProductGroupCreate />} />
                      <Route path="show/:id" element={<MuiInferencer />} />
                      <Route path="update/:id" element={<MuiInferencer />} />
                      {/* <Route path="" element={<MuiInferencer />} />
                      <Route path="menus/:id" element={<MuiInferencer />} /> */}
                    </Route>
                    <Route path="/supplementGroups">
                      <Route index element={<SupplementGroupList />} />
                      {/* <Route index element={<MuiInferencer />} /> */}
                      <Route path="create" element={<MuiInferencer />} />
                      <Route path="show/:id" element={<MuiInferencer />} />
                      <Route path="update/:id" element={<MuiInferencer />} />
                      {/* <Route path="" element={<MuiInferencer />} />
                      <Route path="menus/:id" element={<MuiInferencer />} /> */}
                    </Route>
                    <Route path="/menus">
                      <Route index element={<MenuList />} />
                      {/* <Route index element={<MuiInferencer />} /> */}
                      <Route path="create" element={<MenuCreate />} />
                      <Route path="show/:id" element={<MenuShow />} />
                      <Route path="update/:id" element={<MenuEdit />} />
                      {/* <Route path="" element={<MuiInferencer />} />
                      <Route path="menus/:id" element={<MuiInferencer />} /> */}
                    </Route>
                    <Route path="/restaurantBrands">
                      <Route index element={<RestaurantBrandList />} />
                      <Route path="create" element={<MuiInferencer />} />
                      <Route path="show/:id" element={<MuiInferencer />} />
                      <Route path="update/:id" element={<MuiInferencer />} />
                    </Route>
                    <Route path="/categories">
                      <Route index element={<CategoryList />} />
                      <Route path="create" element={<CategoryCreate />} />
                      <Route path="show/:id" element={<CategoryShow />} />
                      <Route path="update/:id" element={<CategoryEdit />} />
                    </Route>
                    <Route path="/products">
                      <Route index element={<ProductList />} />
                      <Route path="create" element={<ProductCreate />} />
                      <Route path="show/:id" element={<ProductShow />} /> 
                      <Route path="update/:id" element={<ProductEdit />} /> 
                    </Route>
                    <Route path="/restaurants">
                      <Route index element={<RestaurantList />} />
                      <Route path="create" element={<RestaurantCreate />} />
                      <Route path="show/:id" element={<RestaurantShow />} />
                      <Route path="update/:id" element={<RestaurantEdit />} />
                    </Route>
                    <Route path="/groupedOrders">
                      <Route index element={<OrderList />} />
                      <Route path="create" element={<OrderCreate />} />
                      <Route path="show/:id" element={<OrderShow />} />
                      <Route path="update/:id" element={<OrderEdit />} />
                    </Route>
                    {/* <Route path="/groups">
                    <Route index element={<GroupList />} />
                      <Route path="create" element={<GroupCreate />} />
                      <Route path="show/:id" element={<GroupShow />} />
                      <Route path="update/:id" element={<GroupEdit />} />
                    </Route> */}
                    <Route path="/addresses">
                      <Route index element={<AddressList />} />
                      <Route path="create" element={<AddressCreate />} />
                      <Route path="show/:id" element={<AddressShow />} />
                      <Route path="update/:id" element={<AddressEdit />} />
                    </Route>
                    <Route path="/users">
                      <Route index element={<UserList />} />
                      <Route path="create" element={<UserCreate />} />
                      <Route path="show/:id" element={<UserShow />} />
                      <Route path="update/:id" element={<UserEdit />} />
                    </Route>
                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-outer"
                        fallback={<Outlet />}
                      >
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                  </Route>
                </Routes>

                <RefineKbar />
                <UnsavedChangesNotifier />
                <DocumentTitleHandler />
              </Refine>
              </SocketProvider>
              {/* <DevtoolsPanel /> */}
            </DevtoolsProvider>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
