import React, { useRef, useEffect, useState } from "react";
import orderUpdatedSound from '../../sound/order_update_notif.mp3';
import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useMany,
  useNavigation,
  useUpdate,
  useNotification,
  useOne,
  BaseRecord,
} from "@refinedev/core";
import {
  Show,
  TagField,
  TextFieldComponent as TextField,
  NumberField,
  DateField,
  List,
} from "@refinedev/mui";

import { InputLabel, MenuItem, Select, Typography, Stack, Card, CardHeader, IconButton, Button, CardContent, Stepper, Step, StepLabel, Paper, Avatar, Box } from "@mui/material";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import dayjs from "dayjs";
import { useMediaQuery } from "@refinedev/core/dist/definitions";
import { CourierInfoBox } from "components/courierInfoBox";
import MopedIcon from "@mui/icons-material/Moped";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import io from 'socket.io-client';
import { OrderStatus } from "components/orderStatus";
import { useParams } from "react-router-dom";

import axios from 'axios';

const updateDeliveryGuy = async (
  groupedOrderId: string,
  orders: Array<{ _id: string }>,
  deliveryGuyId: string
): Promise<boolean> => {
  try {
    // Update grouped order
    await axios.put(`https://api.backend.saheat.tn/api/v1/admin/groupedOrders/${groupedOrderId}`, {
      deliveryGuy: deliveryGuyId,
    });
    
    const orderIds = orders.map(order => order._id);
    // Update order
    for (const orderId of orderIds) {
        await axios.patch(`https://api.backend.saheat.tn/api/v1/admin/orders/${orderId}`, {
          deliveryGuy: deliveryGuyId,
        });
      }

    return true;
  } catch (error) {
    console.error('Error updating delivery guy:', error);
    throw error;
  }
};

export default updateDeliveryGuy;



const socket = io('https://api.backend.saheat.tn');
const orderUpdated = new Audio(orderUpdatedSound);



export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<RecordType>();

  const notif = useNotification();
  const { id } = useParams();
  // Add a ref to keep track whether the component is mounted
  const isMountedRef = useRef(true);

  // Use useEffect to set up the interval
  useEffect(() => {
    // Define a function that reloads the data
    const reloadData = async () => {
      if (isMountedRef.current) {
        try {
          await queryResult?.refetch();
        } catch (error) {
          console.error(error);
        }
      }
    };

    
    // Set up an interval to reload data every 5 seconds
    const intervalId = setInterval(reloadData, 5000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
      // Mark as unmounted to prevent setting state after unmount
      isMountedRef.current = false;
    };
  }, [queryResult?.refetch]);

  useEffect(() => {
    socket.on(`orderUpdated-${id}`, (updatedOrder) => {
        if(notif.open) {
            notif.open({
                message: 'Order updated',
                description: `Order ${updatedOrder.groupOrderNumber} has been updated.`,
                type: 'success',
            });
        }
        orderUpdated.play();
        queryResult?.refetch();
    //   refetch(); // Refetch the orders to update the list
    });

    socket.on(`orderDeleted-${id}`, () => {
      
        if(notif.open) {

            notif.open({
                message: 'Order deleted',
                description: `Order ${id} has been deleted.`,
                type: 'progress',
              });
        }
        setTimeout(() => {
            list("groupedOrders"); // Refetch the orders to update the list
        }, 1500);
    });

    return () => {
      socket.off(`orderUpdated-${id}`);
      socket.off(`orderDeleted-${id}`);
    };
  }, []);

  const { goBack, list  } = useNavigation();
  const { mutate } = useUpdate();
  
  const { data, isLoading } = queryResult;
  const [isSmallOrLess, setIsSmallOrLess] = useState(window.innerWidth <= 600);


  const record = data?.data as RecordType | undefined;

//   const [selectedDeliveryGuy, setSelectedDeliveryGuy] = useState(record?.deliveryGuy?._id || "");
const firstOrder = data?.data?.orders?.[0];
console.log(firstOrder)
const latitude = firstOrder?.shippingAddress?.location?.lat;
const longitude = firstOrder?.shippingAddress?.location?.long;

// Construct Google Maps URL only if both coordinates are available
const googleMapsUrl = latitude && longitude
  ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
  : '';
  const canAcceptOrder = record?.status === "Pending";
  const canShipOrder = record?.status === "Pending" || record?.status === "Confirmed" || record?.status === "Ready";
  const canDeliverOrder = record?.status === "Pending" || record?.status === "Confirmed" || record?.status === "Ready" || record?.status === "Shipped";
    const canRejectOrder =
        record?.status === "Pending" ||
        record?.status === "Confirmed" ||
        record?.status === "Ready" ||
        record?.status === "Shipped";

  const { data: productsData, isLoading: productsIsLoading } = useMany({
      resource: "products",
      ids: record?.products || [],
      queryOptions: {
          enabled: !!record && !!record?.products?.length,
      },
  });

  interface RecordType extends BaseRecord {
    totalGroupedPrice: number;
    discountCoupon?: string;
    // Add other properties if necessary
  }
  

  interface CustomFooterProps {
    coupon?: {
      code: string;
      discountType: "Percentage" | "Fixed";
      discountValue: number;
    };
    record?: RecordType;
  }
  
  

  const CustomFooter: React.FC<CustomFooterProps> = ({ coupon, record }) => {
    const translate = useTranslate();
  
    return (
      <Stack direction="column" spacing={1} alignItems="flex-end" p={1}>
        <Typography variant="h6" sx={{ color: "primary.main" }} fontWeight={700}>
          {translate("orders.fields.totalOrderPrice") || "Total Order Price"}
        </Typography>
        <Typography variant="subtitle1">
          {record?.totalGroupedPrice} DT
          {coupon && ` (${translate("afterDiscount") || "after discount"})`}
        </Typography>
        {coupon && (
          <>
            <Typography variant="body2">
              {translate("orders.fields.couponCode") || "Coupon Code"}: {coupon.code}
            </Typography>
            <Typography variant="body2">
              {translate("orders.fields.discountValue") || "Discount Value"}:{" "}
              {coupon.discountType === "Percentage"
                ? `${coupon.discountValue}%`
                : `${coupon.discountValue.toFixed(2)} DT`}
            </Typography>
          </>
        )}
      </Stack>
    );
  };
  
  
  

  
  useEffect(() => {
    // Log the discountCoupon when the record is available
    if (record?.discountCoupon) {
      console.log("Discount Coupon ID:", record.discountCoupon);
    }
  }, [record]);
  
  const { data: deliveryGuysData, isLoading: deliveryGuysIsLoading } = useMany({
    resource: "users?role=delivery_guy",
    ids: [],
  });
  
    const { data: discountCouponData, isLoading: discountCouponIsLoading } = useOne({
      resource: "coupons",
      id: record?.discountCoupon,
      queryOptions: {
          enabled: !!record?.discountCoupon,
      },
  });
  const coupon = discountCouponData?.data;
  const isCouponValid = (coupon: any): coupon is { code: string; discountType: "Percentage" | "Fixed"; discountValue: number } => {
    return (
      coupon &&
      typeof coupon.code === "string" &&
      (coupon.discountType === "Percentage" || coupon.discountType === "Fixed") &&
      typeof coupon.discountValue === "number"
    );
  };
  
  const validCoupon = isCouponValid(coupon) ? coupon : undefined;
  

  if (coupon) {
      console.log("Fetched Coupon:", coupon);
  }

  const statusOrder = ['Delivered', 'Shipped', 'Ready', 'Confirmed', 'Pending', 'Cancelled'];

    // Custom sorting function
    const sortByStatusAndTime = (a: any, b: any) => {
        // Compare by status order first
        let statusComparison = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
        
        if (statusComparison !== 0) {
            // If statuses are different, use the status comparison
            return statusComparison;
        } else {
            // If statuses are the same, compare by estimatedCookingTime
            return a.estimatedCookingTime - b.estimatedCookingTime;
        }
    };

  const handleMutate = (status: string) => {
    if (record) {
      mutate({
        resource: "groupedOrders",
        id: record?._id.toString(),
        values: {
          status,
        },
      });
    }
  };
  const handleMutateDelivery = async (deliveryGuyId: string) => {
    if (record) {
      try {
        console.log(record);
        await updateDeliveryGuy(record._id.toString(), record.orders, deliveryGuyId);
        await queryResult?.refetch();
      } catch (error) {
        console.error('Error updating delivery guhandleMutay:', error);
      }
    }
  };
  
  

const columns = React.useMemo<GridColDef[]>(
  () => [
      {
          field: "name",
          headerName: translate("orders.fields.products"),
          width: 300,
          renderCell: function render({ row }) {
              return (
                  <Stack direction="row" spacing={4} alignItems="center">
                      <Avatar
                          sx={{ width: 108, height: 108 }}
                          src={row.product.coverImage}
                      />
                      <Box>
                          <Typography
                              variant="body1"
                              whiteSpace="break-spaces"
                          >
                              {row.product.name}
                          </Typography>
                      </Box>
                  </Stack>
              );
          },
      },
      {
          field: "restaurant",
          headerName: translate("orders.fields.restaurant"),
          width: 300,
          renderCell: function render({ row }) {
              return (
                  row?.restaurant?.name
              );
          },
      },
      {
          field: "quantity",
          headerName: translate("orders.fields.quantity"),
          width: 150,
          sortable: false,
          renderCell: function render({ row }) {
            return (
                row?.quantity + "x"
            );
        },
      },
      {
          field: "price",
          headerName: translate("orders.fields.price"),
          width: 100,
          type: "number",
      },
  ],
  [translate],
);
useEffect(() => {
  const handleResize = () => {
    setIsSmallOrLess(window.innerWidth <= 600);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

// const isSmallOrLess = useMediaQuery(theme.breakpoints.down("sm"));

  return (
      <Show isLoading={isLoading}>
          <Card>
              <CardHeader
                  sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 2,
                  }}
                  title={
                      <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography variant="h6">
                              {translate("orders.fields.orderNum")}
                          </Typography>
                          <Typography variant="caption">{`#${
                              record?.groupOrderNumber ?? ""
                          }`}</Typography>
                      </Stack>
                  }
                  avatar={
                      <IconButton onClick={goBack}>
                          <ArrowBackOutlinedIcon />
                      </IconButton>
                  }
                  action={
                      <Stack direction="row" spacing={2}>
                          <Button
                              disabled={!canAcceptOrder}
                              variant="outlined"
                              size="small"
                              color="success"
                              startIcon={<CheckOutlinedIcon color="success"/>}
                              onClick={() =>
                                  handleMutate( "Confirmed")
                              }
                          >
                              {translate("orders.buttons.accept")}
                          </Button>
                          <Button
                              disabled={!canShipOrder}
                              variant="outlined"
                              size="small"
                              color="info"
                              startIcon={<CheckOutlinedIcon color="info"/>}
                              onClick={() =>
                                  handleMutate( "Shipped")
                              }
                          >
                              {translate("orders.buttons.shipped")}
                          </Button>
                          <Button
                              disabled={!canDeliverOrder}
                              variant="outlined"
                              size="small"
                              color="secondary"
                              startIcon={<CheckOutlinedIcon color="secondary"/>}
                              onClick={() =>
                                  handleMutate( "Delivered")
                              }
                          >
                              {translate("orders.buttons.delivered")}
                          </Button>
                          <Button
                              disabled={!canRejectOrder}
                              variant="outlined"
                              size="small"
                              color="error"
                              startIcon={<CloseOutlinedIcon color="error" sx={{ bg: "red" }} />}
                              onClick={() =>
                                  handleMutate("Cancelled")
                              }
                          >
                              {translate("orders.buttons.reject")}
                          </Button>
                      </Stack>
                  }
              />
              <CardContent>
                  <Stepper
                      nonLinear
                      activeStep={[
                        {
                          "status": "Pending"
                        },
                        {
                          "status": "Confirmed"
                        },
                        {
                          "status": "Ready"
                        },
                        {
                          "status": "Shipped"
                        },
                        {
                          "status": "Delivered"
                        },
                        {
                          "status": "Cancelled"
                        }
                      ].findIndex(
                          (el: any) => el.status === record?.status,
                      )}
                      orientation={isSmallOrLess ? "vertical" : "horizontal"}
                  >
                      {[
                        {
                          "status": "Pending"
                        },
                        {
                          "status": "Confirmed"
                        },
                        {
                            "status": "Ready"
                        },
                        {
                          "status": "Shipped"
                        },
                        {
                          "status": "Delivered"
                        },
                        {
                          "status": "Cancelled"
                        }
                      ].map((event: any, index: number) => (
                          <Step key={index}>
                              <StepLabel
                                  optional={
                                      <Typography variant="caption">
                                          {event.date &&
                                              dayjs(event.date).format(
                                                  "L LT",
                                              )}
                                      </Typography>
                                  }
                                  error={event.status === "Cancelled"}
                              >
                                  {event.status}
                              </StepLabel>
                          </Step>
                      ))}
                  </Stepper>
                  {discountCouponData && discountCouponData.data.length > 0 && (
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="body1" fontWeight="bold">
                        {translate("orders.fields.discountCoupon")}
                        </Typography>
                        <Typography variant="body2">
                        {translate("orders.fields.couponCode")}: {discountCouponData.data[0].code}
                        </Typography>
                        <Typography variant="body2">
                        {translate("orders.fields.discountValue")}: 
                        {discountCouponData.data[0].discountType === 'Percentage' 
                            ? `${discountCouponData.data[0].discountValue}%` 
                            : `${discountCouponData.data[0].discountValue} DT`}
                        </Typography>
                    </Box>
                    )}

              </CardContent>
          </Card>
            {/* <InputLabel id="delivery-guy-label">
                {translate("orders.fields.deliveryGuy.deliveryGuy")}
            </InputLabel> */}
          
         
         {// check if every order isn't pending
          record?.orders?.every((order: any) => order.status !== 'Pending') && (  <Paper sx={{ padding: 2, marginTop: 2, marginBottom: 2  }}>
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent={isSmallOrLess ? "center" : "space-between"}
                >
                    <Stack
                        direction={isSmallOrLess ? "column" : "row"}
                        alignItems={isSmallOrLess ? "center" : "flex-start"}
                        textAlign={isSmallOrLess ? "center" : "left"}
                        gap={2}
                    >
                        <Avatar
                            alt={record?.deliveryGuy?.last_name}
                            src={"https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1174.jpg"}
                            sx={{ width: 100, height: 100 }}
                        />
                        <Box>
                            
                            <Typography>Livreur</Typography>
                            <Typography>
                                <Select
                                    labelId="delivery-guy-label"
                                    id="delivery-guy-select"
                                    value={record?.deliveryGuy?._id || ""}
                                    onChange={(event) => {
                                        // setSelectedDeliveryGuy(event.target.value)
                                        handleMutateDelivery(event.target.value);
                                    }}
                                    label={translate("orders.fields.deliveryGuy")}
                                    disabled={deliveryGuysIsLoading}
                                    >
                                    {deliveryGuysData?.data.map((deliveryGuy: any) => (
                                        <MenuItem key={deliveryGuy._id} value={deliveryGuy._id}>
                                        {`${deliveryGuy?.first_name} ${deliveryGuy?.last_name}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Typography>
                            {/* <Typography variant="caption">
                                ID #{record?.deliveryGuy?._id}
                            </Typography> */}
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        gap={2}
                        padding={1}
                        flexWrap="wrap"
                        justifyContent="center"
                    >
                        <CourierInfoBox
                            text={translate("orders.fields.deliveryGuy.phone_number")}
                            icon={<PhoneIphoneIcon sx={{ fontSize: 36 }} />}
                            value={record?.deliveryGuy?.phone_number}
                        />
                        <CourierInfoBox
                            text={translate("orders.fields.deliveryGuy.delivery_time")}
                            icon={<MopedIcon sx={{ fontSize: 36 }} />}
                            value="15:05"
                        />
                    </Stack>
                </Stack>
            </Paper>)
        }
        
        <Paper sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
            <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent={isSmallOrLess ? 'center' : 'space-between'}
            >
                <Stack
                direction={isSmallOrLess ? 'column' : 'row'}
                alignItems={isSmallOrLess ? 'center' : 'flex-start'}
                textAlign={isSmallOrLess ? 'center' : 'left'}
                gap={2}
                >
                <Avatar
                    alt={`${data?.data?.customer?.last_name}`}
                    src={'https://i.pravatar.cc/300'}
                    sx={{ width: 100, height: 100 }}
                />
                <Box>
                    <Typography>Client</Typography>
                    <Typography variant="h6">
                    {data?.data?.customer?.last_name} {data?.data?.customer?.first_name}
                    </Typography>
                    {googleMapsUrl && (
                    <Button
                        component="a"
                        href={googleMapsUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                        sx={{ fontWeight: 'bold' }} 
                        
                    >
                    <Typography variant="body1" fontWeight="bold">
                        {translate("View address on map")}
                    </Typography>
                        
                  </Button>
                  
                    )}
                </Box>
                </Stack>
                <Stack
                        direction="row"
                        gap={2}
                        padding={1}
                        flexWrap="wrap"
                        justifyContent="center"
                    >
                        <CourierInfoBox
                            text={translate("orders.fields.deliveryGuy.phone_number")}
                            icon={<PhoneIphoneIcon sx={{ fontSize: 36 }} />}
                            value={record?.customer?.phone_number}
                        />
                    </Stack>
            </Stack>
        </Paper>

        <Stack gap={1}>
          {record?.orders?.sort(sortByStatusAndTime).map((order: any) => {
            return (
              <Stack key={order?._id}>
                <List
                  headerProps={{
                    title: (
                      <Box>
                        <Typography>
                          Commande # {order?.orderNumber} <OrderStatus status={order?.status} />
                        </Typography>
                        <Typography variant="caption">
                          {order?.estimatedCookingTime ? "Estimation: " + order?.estimatedCookingTime + " minutes" : ""}
                        </Typography>
                      </Box>
                    ),
                  }}
                  canCreate={false}
                  breadcrumb={false}
                >
                  <DataGrid
                    autoHeight
                    columns={columns}
                    rows={order?.products || []}
                    hideFooterPagination
                    rowHeight={124}
                    getRowId={row => row._id}
                  />
                </List>
              </Stack>
            );
          })}{record && (
            <CustomFooter coupon={validCoupon} record={record} />
          )}
          
          

        </Stack>
      </Show>
  );
};
