import React, { createContext, useContext, useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import newOrderSound from '../sound/new_order_notif.mp3';
import { useNotification } from "@refinedev/core";


const SocketContext = createContext<Socket | null>(null);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let socketRef = useRef<Socket | null>(null);
    const notif = useNotification();
    const orderCreatedSound = new Audio(newOrderSound);

    useEffect(() => {
        socketRef.current = io('https://api.backend.saheat.tn'); 

        const socket = socketRef.current;

        // Socket event listeners
        socket.on('groupOrderCreated', (newOrder) => {
            if (notif.open) {
                notif.open({
                    message: 'New order received',
                    type: 'success',
                });
            }
            orderCreatedSound.play();
        });

        socket.on('groupOrderUpdated', (updatedOrder) => {
            if (notif.open) {
                notif.open({
                    message: 'Order updated',
                    description: `Order ${updatedOrder.groupOrderNumber} has been updated.`,
                    type: 'success',
                });
            }
        });

        socket.on('groupOrderDeleted', (deletedOrder) => {
            if (notif.open) {
                notif.open({
                    message: 'Order deleted',
                    description: `Order ${deletedOrder.groupOrderNumber} has been deleted.`,
                    type: 'progress',
                });
            }
        });

        // Cleanup socket connection on unmount
        return () => {
            socket.off('groupOrderCreated');
            socket.off('groupOrderUpdated');
            socket.off('groupOrderDeleted');
            socket.disconnect();
        };
    }, [notif]);
    
    return (
        <SocketContext.Provider value={socketRef.current}>
            {children}
        </SocketContext.Provider>
    );
};

// Custom hook to use the socket in any component
export const useSocket = () => {
    return useContext(SocketContext);
};
